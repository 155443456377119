<template>
  <Animation3 />

  <!-- SEO Tags -->
  <teleport to="head">
    <meta name="description" :content="Seo.Description" />
    <meta name="keywords" :content="Seo.Keywords" />
    <link rel="alternate" hreflang="x-default" :href="Seo.HreflangDefault" />
    <link rel="alternate" hreflang="en-US" :href="Seo.HreflangCountry" />

    <!-- Open Graph Tags -->
    <meta property="og:title" :content="Seo.OpenGraph.Title" />
    <meta property="og:site_name" :content="Seo.OpenGraph.SiteName" />
    <meta property="og:url" :content="Seo.OpenGraph.Url" />
    <meta property="og:description" :content="Seo.OpenGraph.Description" />
    <meta property="og:type" :content="Seo.OpenGraph.Website" />
    <meta property="og:image" :content="Seo.OpenGraph.Image" />
    <!-- ./Open Graph Tags -->

    <!-- Twitter Card Tags -->
    <meta name="twitter:card" :content="Seo.TwitterCard.Card" />
    <meta name="twitter:site" :content="Seo.TwitterCard.Site" />
    <meta name="twitter:title" :content="Seo.TwitterCard.Title" />
    <meta name="twitter:description" :content="Seo.TwitterCard.Description" />
    <meta name="twitter:image" :content="Seo.TwitterCard.Image" />
    <!-- ./Twitter Card Tags -->
  </teleport>
  <!-- ./SEO Tags -->

  <main id="projects" class="page">
    <!-- <Animation3 /> -->
    <div class="container pt-48 pb-8" style="overflow: initial;">
      <h1
        data-aos="fade-up"
        data-aos-delay="1000"
        data-aos-duration="1000"
        class="h1-project-font text-5xl  text-black tracking-normal mbs:tracking-tight leading-none sm:text-7xl lg:mr-92 xl:text-8xl "
      >
        <span>
          {{ content.Heading1 }}
        </span>
        <br />
        <span>
          {{ content.Heading2 }}
        </span>
      </h1>
      <p
        data-aos="fade-up"
        data-aos-delay="1500"
        data-aos-duration="1500"
        class=" p-project-font pt-6 pb-2 sm:mr-20 sm:pb-6 sm:text-lg lg:text-xl xl:mr-96 xl-pr-96 xl:text-2xl xl:tracking-normalss"
      >
        {{ content.Description }}
      </p>
    </div>
    <section class="pt-2 overflow-hidden">
      <div class="tild">
        <div
          class="grid projects-grid lg:grid-cols-3 md:grid-cols-2 grid-rows-1 gap-0 rotate-1"
        >
          <!-- <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              space-x-0
              iphone678:space-x-0
              pixle:space-x-0
              infinix:space-x-0
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <AutotradiaSvg />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div>
              <img
                class="
                p-0
                mb-4
                transform
                scale-100
                transition
                duration-500
                ease-in-out
                transform
                hover:scale-105 
              "
                :src="require('@/assets/images/jointstore.png')"
                alt="No Image"
              />
            </div>
          </div> -->

          <!--  Codefest -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' +
                require('@/assets/imgs/projects/codefest-bg.jpg') +
                ')',
            }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              space-x-0
              iphone678:space-x-0
              pixle:space-x-0
              infinix:space-x-0
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/codefest.png"
                  :style="{ maxHeight: '40px' }"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="
                p-0
                mb-4
               tonino1
              "
                :src="require('@/assets/imgs/projects/code2.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                mb-4
               tonino2
              "
                :src="require('@/assets/imgs/projects/code1.png')"
                alt="No Image"
              />
            </div>
          </div>
          <!-- Screenflash -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-10 transition project-display"
            :style="{ background: '#E61A4F' }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/screenflash.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group">
              <img
                class="
                p-0
                msg1
              "
                :src="require('@/assets/imgs/projects/screen2.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                msg2
              "
                :src="require('@/assets/imgs/projects/screen1.png')"
                alt="No Image"
              />
            </div>
          </div>
          <!--  Joint Store -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' +
                require('@/assets/imgs/projects/joint-store-bg.png') +
                ') center left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              space-x-0
              iphone678:space-x-0
              pixle:space-x-0
              infinix:space-x-0
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/joint-store.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group">
              <div>
                <img
                  class="
                p-0
                mb-4
                joint-mob
              "
                  :src="require('@/assets/imgs/projects/joint-web.png')"
                  alt="No Image"
                />
                <img
                  class="
                p-0
                mb-4
               joint-web
              "
                  :src="require('@/assets/imgs/projects/joint-mob.png')"
                  alt="No Image"
                />
              </div>
            </div>
          </div>
          <!--  Tonino Pizzeria -->
          <div
            class="hover:bg-red-900 project-background-auotradia justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' +
                require('@/assets/imgs/projects/tonino-bg.jpg') +
                ') center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              space-x-0
              iphone678:space-x-0
              pixle:space-x-0
              infinix:space-x-0
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/tonino.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="
                p-0
                mb-4
                tonino1
              "
                :src="require('@/assets/imgs/projects/tonino1.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                mb-4
                tonino2
              "
                :src="require('@/assets/imgs/projects/tonino2.png')"
                alt="No Image"
              />
            </div>
          </div>
          <!-- Messenger App -->
          <div
            class="hover:bg-red-900 project-background-auotradia justify-items-stretch z-10 transition project-display"
            :style="{ background: 'linear-gradient(#00D1c2, #4c39fc)' }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/sharebius.png"
                />
              </span>
              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group msgApp">
              <img
                class="
                p-0
               msg1
              "
                :src="require('@/assets/imgs/projects/msg2.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
               msg2
              "
                :src="require('@/assets/imgs/projects/msg1.png')"
                alt="No Image"
              />
            </div>
          </div>
          <!-- Cannabisbit -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' +
                require('@/assets/imgs/projects/cannabisbit-bg.jpg') +
                ') center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row              
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/cannabisbit.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="group-hover:bg-gray-300 img-group">
              <img
                class="
                p-0
                joint-mob
               
              "
                :src="require('@/assets/imgs/projects/canbit-web.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                 joint-web
              "
                :src="require('@/assets/imgs/projects/canbit-mob.png')"
                alt="No Image"
              />
            </div>
          </div>

          <!-- Elite Hospital -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' +
                require('@/assets/imgs/projects/elite-hospital-bg.jpg') +
                ') center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row              
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/elite-hospital.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="
                p-0
                tonino1
              "
                :src="require('@/assets/imgs/projects/hospital2.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                tonino2
              "
                :src="require('@/assets/imgs/projects/hospital1.png')"
                alt="No Image"
              />
            </div>
          </div>

          <!-- Curasend -->
          <div
            class="hover:bg-red-900 project-background-auotradia justify-items-stretch z-10 transition project-display"
            :style="{ background: '#83C7B0' }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/curasend.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group">
              <img
                class="
                p-0
                msg1
              "
                :src="require('@/assets/imgs/projects/scan1.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                msg2
              "
                :src="require('@/assets/imgs/projects/scan2.png')"
                alt="No Image"
              />
            </div>
          </div>
          <!-- wilkomen -->
          <div
            class="hover:bg-red-900 project-background-auotradia justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' +
                require('@/assets/imgs/projects/walk-bg.png') +
                ') center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row              
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/2t.gif"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="
                p-0
                tonino1
              "
                :src="require('@/assets/imgs/projects/wilko2.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                tonino2
              "
                :src="require('@/assets/imgs/projects/wilko1.png')"
                alt="No Image"
              />
            </div>
          </div>
          <!-- Camera  -->
          <div
            class="hover:bg-red-900 project-background-auotradia justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' + require('@/assets/imgs/projects/es.png') + ') center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row              
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/camera-logo.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="
                p-0
                tonino1
              "
                :src="require('@/assets/imgs/projects/camera2.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                tonino2
              "
                :src="require('@/assets/imgs/projects/camera1.png')"
                alt="No Image"
              />
            </div>
          </div>

          <!-- Stark Tech  -->
          <div
            class="hover:bg-red-900 project-background-auotradia justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' +
                require('@/assets/imgs/projects/stark-bg.png') +
                ') center ',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row              
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/stark-logo.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="
                p-0
                tonino1
              "
                :src="require('@/assets/imgs/projects/stark2.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                tonino2
              "
                :src="require('@/assets/imgs/projects/stark1.png')"
                alt="No Image"
              />
            </div>
          </div>

          <!-- Cannabis World -->
          <div
            class="hover:bg-red-900 project-background-auotradia justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'linear-gradient(#00D1c2, #4c39fc)',
            }"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row              
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/sharebius.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="
                p-0
                tonino1
              "
                :src="require('@/assets/imgs/projects/canabis2.png')"
                alt="No Image"
              />
              <img
                class="
                p-0
                tonino2
              "
                :src="require('@/assets/imgs/projects/canabis1.png')"
                alt="No Image"
              />
            </div>
          </div>

          <!--  Autotradia -->
          <div
            class="hover:bg-red bg-gray-800 justify-items-stretch z-0 transition project-display"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              space-x-0
              iphone678:space-x-0
              pixle:space-x-0
              infinix:space-x-0
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <AutotradiaLogo />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div>
              <img
                class="
                p-0
                mb-4
                transform
                scale-100
                transition
                duration-500
                ease-in-out
                transform
                hover:scale-105 
              "
                :src="require('@/assets/imgs/projects/autotradia.png')"
                alt="No Image"
              />
            </div>
          </div>

          <!-- Support App -->
          <div
            class="hover:bg-gray project-background-auotradia justify-items-stretch z-10 transition project-display"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <SupportAppLogo />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div>
              <img
                class="
                p-0
                transform
                scale-95
                transition
                duration-500
                ease-in-out
                transform
                hover:-translate-y-1 hover:scale-100
              "
                :src="require('@/assets/imgs/projects/support.png')"
                alt="No Image"
              />
            </div>
          </div>
          <!-- Izmir -->
          <div
            class="hover:bg-red project-background-izmir justify-items-stretch z-0 transition project-display"
          >
            <div
              class="
              py-12
              pl-8
              pb-0
              antialiased
              subpixel-antialiased
              flex flex-row              
              items-center
            "
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <IzmirLogo />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="group-hover:bg-gray-300 ">
              <img
                class="
                p-0
                transform
                scale-100
                transition
                duration-500
                ease-in-out
                transform
                hover:-translate-y-1 hover:scale-105
              "
                :src="require('@/assets/imgs/projects/izmir.png')"
                alt="No Image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import Animation3 from "@/components/Animation3";
// import JointStoreLogo from "@/assets/imgs/projects/logo/joint-store.png";
// import CannabisbitLogo from "@/assets/imgs/projects/logo/cannabisbit.png";
// import ToninoLogo from "@/assets/imgs/projects/logo/tonino.png";
// import ScreenflashLogo from "@/assets/imgs/projects/logo/screenflash.png";
// import CannabisWorldLogo from "@/assets/imgs/projects/logo/cannabis-world.png";
// import EliteHospitalLogo from "@/assets/imgs/projects/logo/elite-hospital.png";
import AutotradiaLogo from "@/assets/imgs/projects/logo/autotradia_logo.svg";
import SupportAppLogo from "@/assets/imgs/sal-01.svg";
import IzmirLogo from "@/assets/imgs/projects/logo/izmir_logo.svg";
import ArrowSvg from "@/assets/imgs/projects/arrow1.svg";

import { LanguageStrings } from "@/languages";
import { SeoStrings } from "@/seo";

export default {
  data() {
    return {
      content: [],
      Seo: {},
    };
  },
  components: {
    Animation3,
    ArrowSvg,
    AutotradiaLogo,
    SupportAppLogo,
    IzmirLogo,
  },
  created() {
    localStorage.getItem("siteLanguage") === "GR"
      ? ((this.content = LanguageStrings.gr.ProjectPage),
        (this.Seo = SeoStrings.gr.Projects))
      : ((this.content = LanguageStrings.en.ProjectPage),
        (this.Seo = SeoStrings.en.Projects));
    console.log(this.content.MetaTitle);
    document.querySelector("head title").innerText = this.content.MetaTitle;
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
